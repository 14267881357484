import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

/* 

Use this on main.tsx

*/

function SentryCommonConfig() {
  if (import.meta.env.PROD) {
    Sentry.init({
      environment: import.meta.env.VITE_APP_ENV,
      dsn: import.meta.env.SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay(),
        new Sentry.BrowserProfilingIntegration(),
      ],
      tracePropagationTargets: [
        /^https:\/\/api\.production\.ecois\.jp/,
        /^https:\/\/api\.staging\.ecois\.jp/,
        /^https:\/\/admin\.ecois\.jp/,
        /^https:\/\/admin\.production\.ecois\.jp/,
        /^https:\/\/admin\.staging\.ecois\.jp/,
        /^https:\/\/client\.ecois\.jp/,
        /^https:\/\/client\.production\.ecois\.jp/,
        /^https:\/\/client\.staging\.ecois\.jp/,
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      // Set profilesSampleRate to 1.0 to profile every transaction.
      // Since profilesSampleRate is relative to tracesSampleRate,
      // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      // results in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0,
    });

    Sentry.setTag('app', import.meta.env.VITE_APP_NAME); // admin or client
  }
}

export { SentryCommonConfig };
