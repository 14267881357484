import { createContext, PropsWithChildren, useState, useContext } from 'react';

type ErrorContentType = {
  message: string;
  errorObject?: any;
  key: string;
};

type AddErrorProps = Omit<ErrorContentType, 'key'> & Partial<Pick<ErrorContentType, 'key'>>;

type ErrorAlertContextType =
  | {
      errors: ErrorContentType[];
      addError: (message: AddErrorProps) => void;
      removeError: (key: string) => void;
      clearErrors: () => void;
    }
  | undefined;

const ErrorAlertContext = createContext<ErrorAlertContextType>(undefined);

function ErrorAlertProvider({ children }: PropsWithChildren) {
  const [errors, setErrors] = useState<ErrorContentType[]>([]);

  const addError = (errorContent: AddErrorProps) => {
    const key = errorContent.key || Date.now().toString();
    setErrors((prevErrors) => [...prevErrors, { ...errorContent, key: key }]);
  };

  const removeError = (key: string) => {
    setErrors((prevErrors) => prevErrors.filter((prevItem) => prevItem.key !== key));
  };

  const clearErrors = () => {
    setErrors([]);
  };

  return (
    <ErrorAlertContext.Provider
      value={{
        errors,
        addError,
        removeError,
        clearErrors,
      }}
    >
      {children}
    </ErrorAlertContext.Provider>
  );
}

const useErrorAlertContext = () => {
  const context = useContext(ErrorAlertContext);
  if (context === undefined) {
    throw new Error('useError must be used within a ErrorProvider');
  }
  return context;
};

export type { ErrorAlertContextType, ErrorContentType, AddErrorProps };
export { ErrorAlertProvider, useErrorAlertContext };
