import { Endpoint } from './type';

const generateEndpointPath = (endpoint: Endpoint, ...args: any): string => {
  if (typeof endpoint === 'function') {
    return endpoint(...args);
  }
  return endpoint;
};

export { generateEndpointPath };
