import { Endpoints, abstractFetcher, useEcoisSWRMutation } from 'lib/api';
import {
  CreateAdminTokenRequestBody,
  CreateAdminTokenResponseBody,
  CreateAdminTokenUnprocessableEntity,
} from './types';

function useCreateAdminToken() {
  const { data, error, isMutating, trigger, ...props } = useEcoisSWRMutation<
    CreateAdminTokenResponseBody,
    {
      body: CreateAdminTokenRequestBody;
      config: any;
    },
    CreateAdminTokenUnprocessableEntity
  >(Endpoints.CreateAdminToken, abstractFetcher);

  return {
    data,
    error,
    isLoading: isMutating,
    trigger,
    ...props,
  };
}

export { useCreateAdminToken };
