import { Close, KeyboardArrowDown, Menu } from '@mui/icons-material';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Grid,
  styled,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { Link, NavLink, NavLinkProps, useNavigate } from 'react-router-dom';
import { useDropdownMenu } from './hooks/useDropdownMenu';
import { useAuth } from '@/hooks';

type Pages = {
  label: string;
  to: string;
};

const EcoisNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius * 100,
  color: theme.palette.common.white,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    opacity: 0.8,
  },
  '&.active': {
    backgroundColor: theme.palette.primary.dark,
    opacity: 1,
  },
}));

function GlobalMenuBar({ pages, minWidth }: { pages: Pages[]; minWidth?: string | number }) {
  const { profile } = useAuth();
  const { DropdownMenu, props, open } = useDropdownMenu();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (newOpen: boolean) => {
    setOpenDrawer(newOpen);
  };

  return (
    <>
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, minWidth: minWidth, left: 0 }}
        elevation={2}
      >
        <Toolbar
          sx={{
            width: '100vw',
          }}
        >
          <Box>
            <Box
              onClick={() => toggleDrawer(true)}
              color='inherit'
              p={0.5}
              sx={{
                display: { xs: 'block', md: 'none' },
                outline: '1px solid',
                lineHeight: 0,
                borderRadius: 1,
                cursor: 'pointer',
              }}
            >
              <Menu />
            </Box>
          </Box>
          <Typography
            component={Link}
            variant='h6'
            to='/'
            mr={4}
            ml={{
              xs: 2,
              md: 0,
            }}
            sx={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Ecois Admin
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Grid container spacing={2}>
              {pages.map(({ label, to }) => (
                <Grid item key={label}>
                  <EcoisNavLink to={to}>{label}</EcoisNavLink>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}> {/*  Spacer */} </Box>
          <Button
            color='inherit'
            sx={{
              textTransform: 'none',
            }}
            onClick={open}
            endIcon={<KeyboardArrowDown />}
          >
            {profile?.username}
          </Button>
        </Toolbar>
      </AppBar>
      <DropdownMenu {...props} />
      <Drawer
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 280 }} role='presentation'>
          <List>
            <ListItem
              secondaryAction={
                <IconButton edge='end' aria-label='delete' onClick={() => toggleDrawer(false)}>
                  <Close />
                </IconButton>
              }
            >
              <ListItemText>
                <Typography color={'primary.main'} variant='h6'>
                  Ecois Admin
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            {pages.map(({ label, to }) => (
              <ListItem key={label} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(to);
                    toggleDrawer(false);
                  }}
                >
                  <ListItemText>{label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </>
  );
}

export { GlobalMenuBar };
