import { Theme, useTheme } from '@mui/material';

function useLayoutConstants() {
  const theme = useTheme() as Theme;

  const globalMaxWidth = theme.breakpoints.values.xl;
  const globalMinWidth = theme.breakpoints.values.md;
  const subMenuWidth = 160;

  const constants = {
    global: {
      minWidth: globalMinWidth,
      maxWidth: globalMaxWidth,
    },
    component: {
      subMenu: {
        width: subMenuWidth,
      },
    },
  };

  return { ...constants };
}

export { useLayoutConstants };
