import useSWRMutation, {
  MutationFetcher,
  SWRMutationConfiguration,
  SWRMutationResponse,
} from 'swr/mutation';
import { Arg } from './abstractFetcher';
import { EcoisKeys } from './type';

function useEcoisSWRMutation<Data = any, ExtraArg extends Arg = never, Error = any>(
  key: EcoisKeys,
  fetcher: MutationFetcher<Data, ExtraArg, EcoisKeys>,
  options?: SWRMutationConfiguration<Data, Error, ExtraArg, EcoisKeys>,
): SWRMutationResponse<Data, Error, ExtraArg, EcoisKeys> {
  return useSWRMutation(key, fetcher, options);
}

export { useEcoisSWRMutation };
