import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Stack, Typography, Button } from '@mui/material';
import { useErrorAlertContext } from './ErrorAlertContext';

type ErrorAlertComponentProps = { float?: boolean };
function ErrorAlertComponent({ float = false }: ErrorAlertComponentProps) {
  const { errors, removeError } = useErrorAlertContext();

  const floatStyleProps = {
    position: 'fixed',
    width: '400px',
    zIndex: 9999,
    top: 80,
    right: '24px',
    // transform: 'translateX(-50%)',
  };

  if (errors.length === 0) {
    return null;
  }
  return (
    <>
      <Stack sx={float ? floatStyleProps : {}} spacing={2} my={1}>
        {errors.map((error) => (
          <Alert
            severity='error'
            key={error.key}
            sx={{
              '.MuiAlert-action': {
                flexShrink: 0,
              },
            }}
            action={
              <Button
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  removeError(error.key);
                }}
                endIcon={<CloseIcon fontSize='small' />}
              >
                閉じる
              </Button>
            }
          >
            <AlertTitle>エラーが発生しました</AlertTitle>
            <Typography variant={'caption'} whiteSpace={'pre-wrap'}>
              {error.message}
            </Typography>
          </Alert>
        ))}
      </Stack>
    </>
  );
}

export { ErrorAlertComponent };
