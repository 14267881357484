import { Endpoints, abstractFetcher, useEcoisSWRMutation } from 'lib/api';
import { ReadAdminUserProfileResponse } from './types';

function useReadAdminUserProfile() {
  const { data, error, isMutating, trigger, ...props } =
    useEcoisSWRMutation<ReadAdminUserProfileResponse>(
      Endpoints.ReadAdminUserProfile,
      abstractFetcher,
    );

  return {
    data,
    error,
    isLoading: isMutating,
    trigger,
    ...props,
  };
}

export { useReadAdminUserProfile };
