import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useErrorAlertContext } from './ErrorAlertContext';

function useErrorAlert() {
  const context = useErrorAlertContext();

  const location = useLocation();
  useEffect(() => {
    context.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    ...context,
  };
}

export { useErrorAlert };
