import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { amber } from '@mui/material/colors';
import { LinkProps } from '@mui/material/Link';
import { forwardRef, ReactNode } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(function LinkBehaviorComponent(props, ref) {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Arial',
      'Hiragino Sans',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
  palette: {
    attention: {
      light: amber[400],
      main: amber[700],
      dark: amber[800],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
  },
});

const EmotionNoVenderPrefix = createCache({
  key: 'noprefixer',
  stylisPlugins: [],
});

export const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={EmotionNoVenderPrefix}>
        <CssBaseline />
        {children}
      </CacheProvider>
    </ThemeProvider>
  );
};
