import { createContext } from 'react';
import { AuthState } from '../types/Auth';
import { AdminUser } from '@/types';

export type AuthContextType = {
  authState: AuthState;
  accessToken: string | null;
  setAuthState: (status: AuthState) => void;
  login: (param: { username: string; password: string }) => Promise<void>;
  logout: (options?: { callback?: () => void }) => void;
  isLoading: boolean;
  profile: AdminUser | undefined;
};

export const AuthContext = createContext<AuthContextType | null>(null);
