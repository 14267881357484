import { AddErrorProps } from './ErrorAlertContext';

type SwrCommonOnErrorHandlerProps = {
  err: any;
  addError: (props: AddErrorProps) => void;
};

function swrCommonOnErrorHandler({ err, addError }: SwrCommonOnErrorHandlerProps) {
  const status = err.response.status;
  const serverErrorCode = err.response.data.error.code;

  addError({
    message: generateErrorMessage(status, serverErrorCode),
    errorObject: err,
    key: Date.now().toString(),
  });
}

function generateErrorMessage(status: number, errorCode?: string) {
  return `${generateErrorMessageFromStatus(status)}(${errorCode})`;
}

function generateErrorMessageFromStatus(status: number) {
  switch (status) {
    case 400:
      return 'リクエストが不正です';
    case 401:
      return '認証に失敗しました';
    case 403:
      return '権限がありません';
    case 409:
      return 'すでに登録されています';
    case 422:
      return 'データの更新に失敗しました';
    case 500:
      return '予期せぬサーバーエラーが発生しました';
    default:
      return 'エラーが発生しました';
  }
}

export { swrCommonOnErrorHandler };
