import { Menu, ListSubheader, MenuItem, Divider, List } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@/context/AuthContext';
import { useAuth } from '@/hooks';

function useDropdownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openState = Boolean(anchorEl);

  function open(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function close() {
    setAnchorEl(null);
  }

  const props = {
    anchorEl,
    openState,
    onClose: close,
  };

  return { props, open, DropdownMenu };
}

function DropdownMenu({
  anchorEl,
  openState,
  onClose,
}: {
  anchorEl: HTMLElement | null;
  openState: boolean;
  onClose: () => void;
}) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  // ロールにより表示されるメニューを制御
  const authContext = useContext(AuthContext);
  const role = authContext?.profile?.role;
  const isAdminControlDisplayable = role === 'superadmin';

  const handleLogout = () => {
    logout({
      callback: () => {
        navigate('/login');
      },
    });
  };

  return (
    <Menu
      id='menu-globalmenu-bar'
      anchorEl={anchorEl}
      open={openState}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiMenu-paper': {
          minWidth: 200,
        },
      }}
    >
      {isAdminControlDisplayable && (
        <List>
          {/* 権限により制限されるメニュー */}
          <ListSubheader> 管理メニュー </ListSubheader>
          <MenuItem
            onClick={() => {
              onClose();
              navigate('/adminusers');
            }}
          >
            アドミンユーザー
          </MenuItem>
          <Divider />
        </List>
      )}
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );
}

export { useDropdownMenu };
