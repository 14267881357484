import { swrCommonOnErrorHandler, useErrorAlertContext } from 'lib/ErrorAlert';
import { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';

function SWRConfigProvider({ children }: PropsWithChildren) {
  const { addError } = useErrorAlertContext();

  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        onError: async (err) => {
          swrCommonOnErrorHandler({
            err,
            addError,
          });
        },
      }}
    >
      {children}
    </SWRConfig>
  );
}

export { SWRConfigProvider };
