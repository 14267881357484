import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useAuthLS } from '../../hooks/useAuthLS';

const BASE_URL = import.meta.env.VITE_HOST;

const axiosBaseInstance = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: { indexes: null },
});

const AddBaseInterceptors = (instance: AxiosInstance) => {
  const authLS = useAuthLS();

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = authLS.getToken('accessToken');

      const { headers } = config;

      if (!headers['Content-Type']) {
        headers.set('Content-Type', 'application/json');
      }
      if (token && !headers.Authorization) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // リフレッシュリクエストが走っているかどうかを判定するための変数
  let refreshRequest: Promise<AxiosResponse> | null = null;
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      /* 
      
      認証エラーの場合、リフレッシュトークンを使ってアクセストークンを更新する。
      
      */
      if (error.response.status === 401 || error.response.status === 403) {
        if (originalRequest.url === `/auth/token/refresh` || originalRequest._retry) {
          // リフレッシュトークンの更新に失敗した場合、アクセストークンやリフレッシュトークンを削除する。
          authLS.removeToken('both');
          refreshRequest = null;
          return Promise.reject(error);
        }

        if (authLS.getToken('refreshToken')) {
          // リフレッシュリクエストが走っていない場合、リフレッシュリクエストを走らせる。
          if (!refreshRequest) {
            refreshRequest = instance
              .post(
                `/auth/token/refresh`,
                {},
                { headers: { Authorization: `Bearer ${authLS.getToken('refreshToken')}` } },
              )
              .then((res) => {
                authLS.setToken({
                  accessToken: res.data.access_token,
                  refreshToken: res.data.refresh_token,
                });
                refreshRequest = null;
                return res;
              })
              .then((res) => {
                // リフレッシュトークンが更新された場合､必ず/meアクセスできるか確認する
                // アクセスできる場合は続行､できない場合はログイン画面に遷移する
                instance
                  .get(`/users/me`)
                  .then((res) => {
                    return res;
                  })
                  .catch((e) => {
                    navigateToLogin();
                    console.log(e);
                    authLS.removeToken('both');
                    return Promise.reject(e);
                  });
                return res;
              })
              .catch((e) => {
                // リフレッシュトークンの更新に失敗した場合、アクセストークンやリフレッシュトークンを削除する。
                refreshRequest = null;
                authLS.removeToken('both');
                navigateToLogin();
                throw e;
              });
          }

          // リフレッシュトークンの更新に成功した場合、リクエストを再実行する。
          return refreshRequest.then((res) => {
            originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
            // リトライフラグを立てる(取得したアクセストークンに不備があった場合の無限ループを防ぐため)
            originalRequest._retry = true;
            return instance(originalRequest);
          });
        } else {
          // リフレッシュトークンが存在しない場合、ログイン画面に遷移する
          navigateToLogin();
        }
      }
      return Promise.reject(error);
    },
  );
};

function navigateToLogin() {
  window.location.href = '/login';
}

AddBaseInterceptors(axiosBaseInstance);
export { axiosBaseInstance, axios };
export * from 'axios';
