import { axiosBaseInstance } from '../axios';
import { generateEndpointPath } from './generateEndpointPath';
import { EcoisKeys } from './type';

type Arg = {
  body?: any;
  urlParameter?: any;
  queryParameter?: any;
  config?: any;
};

type FetcherResponse = any;

const abstractFetcher = async <T extends Arg, R extends FetcherResponse>(
  key: EcoisKeys,
  { arg }: { arg?: T } = {},
): Promise<R> => {
  const { path, method } = key;

  const url = generateEndpointPath(path, arg?.urlParameter);

  const res = await axiosBaseInstance.request<R>({
    url,
    method,
    data: arg?.body,
    params: arg?.queryParameter,
    ...arg?.config,
  });
  return res.data;
};

export { abstractFetcher };
export type { Arg, FetcherResponse };
