import { Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { ErrorAlertComponent } from 'lib/ErrorAlert';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useLayoutConstants } from './Constants';
import { GlobalMenuBar } from '@/components/GlobalMenuBar';

export const AppShell = ({ children }: PropsWithChildren) => {
  const pages = [
    { label: 'ロケーション', to: '/location' },
    { label: '案件', to: '/projects' },
    { label: '案件グループ', to: '/projectgroups' },
    { label: 'クライアントユーザー', to: '/clientusers' },
  ];

  const { global } = useLayoutConstants();

  return (
    <Box
      sx={{ display: 'flex', height: '100vh', minWidth: global.minWidth }}
      justifyContent={'center'}
    >
      <GlobalMenuBar pages={pages} minWidth={global.minWidth} />
      <Box
        maxWidth='xl'
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}
        py={1}
        px={3}
      >
        <Toolbar />
        <ErrorAlertComponent float />
        <Outlet />
        {children}
      </Box>
    </Box>
  );
};
