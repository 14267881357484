import { Method } from './helper/type';

type EndpointInfo = {
  method: Method;
  path: string | ((...args: any[]) => string);
};

class Endpoints {
  static ListContractTerms: EndpointInfo = {
    method: 'GET',
    path: '/contract_terms',
  };

  static CreateContractTerm: EndpointInfo = {
    method: 'POST',
    path: '/contract_terms',
  };

  static ReadContractTerm: EndpointInfo = {
    method: 'GET',
    path: ({ contractTermId }: { contractTermId: any }) => `/contract_terms/${contractTermId}`,
  };

  static UpdateContractTerm: EndpointInfo = {
    method: 'PATCH',
    path: ({ contractTermId }: { contractTermId: any }) => `/contract_terms/${contractTermId}`,
  };

  static DeleteContractTerm: EndpointInfo = {
    method: 'DELETE',
    path: ({ contractTermId }: { contractTermId: any }) => `/contract_terms/${contractTermId}`,
  };

  static ListUtilities: EndpointInfo = {
    method: 'GET',
    path: '/utilities',
  };

  static CreateUtility: EndpointInfo = {
    method: 'POST',
    path: '/utilities',
  };

  static ReadUtility: EndpointInfo = {
    method: 'GET',
    path: ({ utilityId }: { utilityId: any }) => `/utilities/${utilityId}`,
  };

  static UpdateUtility: EndpointInfo = {
    method: 'PATCH',
    path: ({ utilityId }: { utilityId: any }) => `/utilities/${utilityId}`,
  };

  static DeleteUtility: EndpointInfo = {
    method: 'DELETE',
    path: ({ utilityId }: { utilityId: any }) => `/utilities/${utilityId}`,
  };

  static ListUnitcosts: EndpointInfo = {
    method: 'GET',
    path: '/unitcosts',
  };

  static ReplaceUnitcost: EndpointInfo = {
    method: 'PUT',
    path: '/unitcosts',
  };

  static ListStandardData: EndpointInfo = {
    method: 'GET',
    path: '/standard_data',
  };

  static ReplaceStandardData: EndpointInfo = {
    method: 'PUT',
    path: '/standard_data',
  };

  static ListReductionTargetData: EndpointInfo = {
    method: 'GET',
    path: '/reduction_target_data',
  };

  static ReplaceReductionTargetData: EndpointInfo = {
    method: 'PUT',
    path: '/reduction_target_data',
  };

  static ListLocations: EndpointInfo = {
    method: 'GET',
    path: '/locations',
  };

  static CreateLocation: EndpointInfo = {
    method: 'POST',
    path: '/locations',
  };

  static UpdateLocation: EndpointInfo = {
    method: 'PATCH',
    path: ({ locationId }: { locationId: any }) => `/locations/${locationId}`,
  };

  static ReadLocation: EndpointInfo = {
    method: 'GET',
    path: ({ locationId }: { locationId: any }) => `/locations/${locationId}`,
  };

  static DeleteLocation: EndpointInfo = {
    method: 'DELETE',
    path: ({ locationId }: { locationId: any }) => `/locations/${locationId}`,
  };

  static ListUnits: EndpointInfo = {
    method: 'GET',
    path: '/units',
  };

  static CreateUnit: EndpointInfo = {
    method: 'POST',
    path: '/units',
  };

  static ReadUnit: EndpointInfo = {
    method: 'GET',
    path: ({ unitId }: { unitId: any }) => `/units/${unitId}`,
  };

  static UpdateUnit: EndpointInfo = {
    method: 'PATCH',
    path: ({ unitId }: { unitId: any }) => `/units/${unitId}`,
  };

  static DeleteUnit: EndpointInfo = {
    method: 'DELETE',
    path: ({ unitId }: { unitId: any }) => `/units/${unitId}`,
  };

  static ListDatapoints: EndpointInfo = {
    method: 'GET',
    path: '/datapoints',
  };

  static CreateDatapoint: EndpointInfo = {
    method: 'POST',
    path: '/datapoints',
  };

  static ReadDatapoint: EndpointInfo = {
    method: 'GET',
    path: ({ datapointId }: { datapointId: any }) => `/datapoints/${datapointId}`,
  };

  static UpdateDatapoint: EndpointInfo = {
    method: 'PATCH',
    path: ({ datapointId }: { datapointId: any }) => `/datapoints/${datapointId}`,
  };

  static UpdateDatapointsAvailable: EndpointInfo = {
    method: 'PATCH',
    path: `/datapoints/available`,
  };

  static DeleteDatapoint: EndpointInfo = {
    method: 'DELETE',
    path: ({ datapointId }: { datapointId: any }) => `/datapoints/${datapointId}`,
  };

  static ListData: EndpointInfo = {
    method: 'GET',
    path: '/data',
  };

  static CreateData: EndpointInfo = {
    method: 'POST',
    path: '/data',
  };

  static DeleteData: EndpointInfo = {
    method: 'DELETE',
    path: '/data',
  };

  static ListInterpolatedData: EndpointInfo = {
    method: 'GET',
    path: '/interpolated_data',
  };

  static CreateInterpolatedData: EndpointInfo = {
    method: 'POST',
    path: '/interpolated_data',
  };

  static DeleteInterpolatedData: EndpointInfo = {
    method: 'DELETE',
    path: '/interpolated_data',
  };

  static ListProjects: EndpointInfo = {
    method: 'GET',
    path: '/projects',
  };

  static CreateProject: EndpointInfo = {
    method: 'POST',
    path: '/projects',
  };

  static ReadProject: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}`,
  };

  static UpdateProject: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}`,
  };

  static UpdateProjectAvailable: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/available`,
  };

  static UpdateProjectKenesIntegrationAvailable: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId }: { projectId: any }) =>
      `/projects/${projectId}/kenes_integration_available`,
  };

  static DeleteProject: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}`,
  };

  static ListProjectGroups: EndpointInfo = {
    method: 'GET',
    path: '/project_groups',
  };

  static CreateProjectGroup: EndpointInfo = {
    method: 'POST',
    path: '/project_groups',
  };

  static ReadProjectGroup: EndpointInfo = {
    method: 'GET',
    path: ({ projectGroupId }: { projectGroupId: any }) => `/project_groups/${projectGroupId}`,
  };

  static UpdateProjectGroup: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectGroupId }: { projectGroupId: any }) => `/project_groups/${projectGroupId}`,
  };

  static DeleteProjectGroup: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectGroupId }: { projectGroupId: any }) => `/project_groups/${projectGroupId}`,
  };

  /**
   * @deprecated
   */
  static ListProjectGroupMembers: EndpointInfo = {
    method: 'GET',
    path: ({ projectGroupId }: { projectGroupId: any }) =>
      `/project_groups/${projectGroupId}/members`,
  };

  static ListProjectGroupMember: EndpointInfo = {
    method: 'POST',
    path: ({ projectGroupId }: { projectGroupId: any }) =>
      `/project_groups/${projectGroupId}/members`,
  };

  static UpsertProjectGroupMembers: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectGroupId }: { projectGroupId: any }) =>
      `/project_groups/${projectGroupId}/members`,
  };

  static DeleteProjectGroupMember: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectGroupId, projectId }: { projectGroupId: any; projectId: any }) =>
      `/project_groups/${projectGroupId}/members/${projectId}`,
  };

  static ListProjectGroupClientUserPermissions: EndpointInfo = {
    method: 'GET',
    path: ({ projectGroupId }: { projectGroupId: any }) =>
      `/project_groups/${projectGroupId}/client_users`,
  };

  static UpsertProjectGroupClientUserPermission: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectGroupId }: { projectGroupId: any }) =>
      `/project_groups/${projectGroupId}/client_users`,
  };

  static DeleteProjectGroupClientUserPermission: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectGroupId, clientUserId }: { projectGroupId: any; clientUserId: any }) =>
      `/project_groups/${projectGroupId}/client_users/${clientUserId}`,
  };

  static ListEnergyTypes: EndpointInfo = {
    method: 'GET',
    path: '/energy_types',
  };

  static ListProjectLocations: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/locations`,
  };

  static UpsertProjectLocations: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/locations`,
  };

  static DeleteProjectLocation: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectId, locationId }: { projectId: any; locationId: any }) =>
      `/projects/${projectId}/locations/${locationId}`,
  };

  static ListProjectNodes: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/nodes`,
  };

  static ListNodes: EndpointInfo = {
    method: 'GET',
    path: `/nodes`,
  };

  static CreateNode: EndpointInfo = {
    method: 'POST',
    path: '/nodes',
  };

  static ReadNode: EndpointInfo = {
    method: 'GET',
    path: ({ nodeId }: { nodeId: any }) => `/nodes/${nodeId}`,
  };

  static UpdateNode: EndpointInfo = {
    method: 'PATCH',
    path: ({ nodeId }: { nodeId: number }) => `/nodes/${nodeId}`,
  };

  static ReplaceNodeDatapoint: EndpointInfo = {
    method: 'PUT',
    path: ({ nodeId }: { nodeId: number }) => `/nodes/${nodeId}/datapoints`,
  };

  static DeleteNode: EndpointInfo = {
    method: 'DELETE',
    path: ({ nodeId }: { nodeId: any }) => `/nodes/${nodeId}`,
  };

  static ListContracts: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/contracts`,
  };

  static CreateContract: EndpointInfo = {
    method: 'POST',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/contracts`,
  };

  static ReadContract: EndpointInfo = {
    method: 'GET',
    path: ({ projectId, contractId }: { projectId: any; contractId: any }) =>
      `/projects/${projectId}/contracts/${contractId}`,
  };

  static UpdateContract: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId, contractId }: { projectId: any; contractId: any }) =>
      `/projects/${projectId}/contracts/${contractId}`,
  };

  static DeleteContract: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectId, contractId }: { projectId: any; contractId: any }) =>
      `/projects/${projectId}/contracts/${contractId}`,
  };

  static CreateUnitcost: EndpointInfo = {
    method: 'POST',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/unitcosts`,
  };

  static ReadUnitcost: EndpointInfo = {
    method: 'GET',
    path: ({ projectId, unitcostId }: { projectId: any; unitcostId: any }) =>
      `/projects/${projectId}/unitcosts/${unitcostId}`,
  };

  static UpdateUnitcost: EndpointInfo = {
    method: 'PATCH',
    path: ({ projectId, unitcostId }: { projectId: any; unitcostId: any }) =>
      `/projects/${projectId}/unitcosts/${unitcostId}`,
  };

  static DeleteUnitcost: EndpointInfo = {
    method: 'DELETE',
    path: ({ projectId, unitcostId }: { projectId: any; unitcostId: any }) =>
      `/projects/${projectId}/unitcosts/${unitcostId}`,
  };

  static CreateAdminToken: EndpointInfo = {
    method: 'POST',
    path: '/auth/token',
  };

  static CreateAdminRefreshToken: EndpointInfo = {
    method: 'POST',
    path: '/auth/token/refresh',
  };

  static ReadAdminUserProfile: EndpointInfo = {
    method: 'GET',
    path: '/users/me',
  };

  static UpdateAdminUserProfile: EndpointInfo = {
    method: 'PATCH',
    path: '/users/me',
  };

  static UpdateAdminUserPassword: EndpointInfo = {
    method: 'PATCH',
    path: ({ adminUserId }: { adminUserId: any }) => `admin_users/${adminUserId}/password`,
  };

  static UpdateAdminUserAvailable: EndpointInfo = {
    method: 'PATCH',
    path: ({ adminUserId }: { adminUserId: any }) => `admin_users/${adminUserId}/available`,
  };

  static UpdateAdminUser: EndpointInfo = {
    method: 'PATCH',
    path: ({ adminUserId }: { adminUserId: any }) => `admin_users/${adminUserId}`,
  };

  static ListAdminUsers: EndpointInfo = {
    method: 'GET',
    path: '/admin_users',
  };

  static CreateAdminUser: EndpointInfo = {
    method: 'POST',
    path: '/admin_users',
  };

  static ListClientUsers: EndpointInfo = {
    method: 'GET',
    path: '/client_users',
  };

  static CreateClientUser: EndpointInfo = {
    method: 'POST',
    path: '/client_users',
  };

  static ReadAdminUser: EndpointInfo = {
    method: 'GET',
    path: ({ adminUserId }: { adminUserId: any }) => `/admin_users/${adminUserId}`,
  };

  static DeleteAdminUser: EndpointInfo = {
    method: 'DELETE',
    path: ({ adminUserId }: { adminUserId: any }) => `/admin_users/${adminUserId}`,
  };

  static UpdateClientUserPassword: EndpointInfo = {
    method: 'PATCH',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}/password`,
  };

  static UpdateClientUserAvailable: EndpointInfo = {
    method: 'PATCH',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}/available`,
  };

  static UpdateClientUser: EndpointInfo = {
    method: 'PATCH',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}`,
  };

  static ReadClientUser: EndpointInfo = {
    method: 'GET',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}`,
  };

  static DeleteClientUser: EndpointInfo = {
    method: 'DELETE',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}`,
  };

  static ListClientUserPermissions: EndpointInfo = {
    method: 'GET',
    path: ({ clientUserId }: { clientUserId: any }) => `/client_users/${clientUserId}/permissions`,
  };

  static UpsertClientUserPermissionProject: EndpointInfo = {
    method: 'PATCH',
    path: ({ clientUserId }: { clientUserId: any }) =>
      `/client_users/${clientUserId}/permissions/projects`,
  };

  static DeleteClientUserPermissionProject: EndpointInfo = {
    method: 'DELETE',
    path: ({ clientUserId, projectId }: { clientUserId: any; projectId: any }) =>
      `/client_users/${clientUserId}/permissions/projects/${projectId}`,
  };

  static UpsertClientUserPermissionProjectGroup: EndpointInfo = {
    method: 'PATCH',
    path: ({ clientUserId }: { clientUserId: any }) =>
      `/client_users/${clientUserId}/permissions/project_groups`,
  };

  static DeleteClientUserPermissionProjectGroup: EndpointInfo = {
    method: 'DELETE',
    path: ({ clientUserId, projectGroupId }: { clientUserId: any; projectGroupId: any }) =>
      `/client_users/${clientUserId}/permissions/project_groups/${projectGroupId}`,
  };

  static Healthcheck: EndpointInfo = {
    method: 'GET',
    path: '/health',
  };

  /**
   *
   *
   *
   * Endpoints for Client App
   *
   *
   *
   */
  static ReadClientUserProfile: EndpointInfo = {
    method: 'GET',
    path: '/users/me',
  };

  static CreateClientToken: EndpointInfo = {
    method: 'POST',
    path: '/auth/token',
  };

  static ListClientProjects: EndpointInfo = {
    method: 'GET',
    path: '/projects',
  };

  static ReadClientProject: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}`,
  };

  static ListClientProjectNodes: EndpointInfo = {
    method: 'GET',
    path: ({ projectId }: { projectId: any }) => `/projects/${projectId}/nodes`,
  };

  static ListClientNodeData: EndpointInfo = {
    method: 'GET',
    path: '/nodes/data',
  };

  static ReadClientNodeData: EndpointInfo = {
    method: 'GET',
    path: ({ nodeId }: { nodeId: number }) => `/nodes/${nodeId}/data`,
  };

  static ReadSummaryTotal: EndpointInfo = {
    method: 'GET',
    path: '/summaries/total',
  };

  static ReadSummaryDetail: EndpointInfo = {
    method: 'GET',
    path: '/summaries/detail',
  };

  static ReadSummaryScore: EndpointInfo = {
    method: 'GET',
    path: '/summaries/score',
  };

  static ListClientGraphData: EndpointInfo = {
    method: 'GET',
    path: '/data',
  };

  static UpdateClientUserProfile: EndpointInfo = {
    method: 'PATCH',
    path: '/users/me',
  };
}

export { Endpoints };
