import { Box, LinearProgress, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const AuthGuard = ({
  children,
  redirectTo,
}: {
  children: ReactElement;
  redirectTo: string;
}) => {
  const { authState } = useAuth();

  if (authState === 'IDLE') {
    return (
      <Box>
        <LinearProgress />
        <Box p={2}>
          <Typography variant='h6'>Ecois Admin</Typography>
          <Typography variant='body1'>
            アプリケーションを起動中です。この表示が続く場合はブラウザリロードを行ってください。
          </Typography>
        </Box>
      </Box>
    );
  }

  if (authState === 'UNAUTHENTICATED') {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};
