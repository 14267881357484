import * as Sentry from '@sentry/browser';
import { useAuthLS } from 'lib/hooks/useAuthLS';
import { ReactNode, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AuthState } from '../types/Auth';
import { useCreateAdminToken, useReadAdminUserProfile } from '@/api';
import { CreateAdminTokenResponseBody, ReadAdminUserProfileResponse } from '@/api/types';
import { AdminUser } from '@/types';

const LOGIN_GRANTED_ROLE = ['superadmin', 'general'];

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setToken, getToken, removeToken } = useAuthLS();
  const { trigger: getUserProfile } = useReadAdminUserProfile();
  const accessToken = getToken('accessToken');
  const { trigger: requestToken, isLoading: isTokenRequestLoading } = useCreateAdminToken();

  const [authState, setAuthState] = useState<AuthState>('INITIAL');

  const [profile, setProfile] = useState<AdminUser | undefined>(undefined);

  function startTransaction() {
    setAuthState('IDLE');
  }

  function determineAuthState({
    responseAdminUserProfile,
  }: {
    responseAdminUserProfile: ReadAdminUserProfileResponse | undefined;
  }): void {
    if (!responseAdminUserProfile) {
      setAuthState('UNAUTHENTICATED');
      return;
    }

    const { role, isAvailable } = responseAdminUserProfile.adminUser;

    if (isAvailable && LOGIN_GRANTED_ROLE.includes(role)) {
      setAuthState('AUTHENTICATED');
    } else {
      setAuthState('UNAUTHENTICATED');
    }
  }

  /**
   *
   * ログイン処理
   * 1. トークン作成
   * 2. ユーザー認可状態確認
   * 3. 認可状態により認証状態を更新
   *
   */
  async function login({ username, password }: { username: string; password: string }) {
    try {
      startTransaction();
      // ログイン処理(トークン作成)
      const response = await triggerLoginRequest(username, password);
      handleLoginResponse(response);

      // ユーザー情報取得
      const responseUserProfile = await getUserProfile();
      handleUserProfile(responseUserProfile);

      // 認証状態のアップデート
      determineAuthState({ responseAdminUserProfile: responseUserProfile });
    } catch (e) {
      console.error(e);
      logout();
    }
  }

  function logout({ callback }: { callback?: () => void } = {}) {
    removeToken('both');
    setAuthState('UNAUTHENTICATED');
    resetProfile();
    if (callback) {
      callback();
    }
  }

  function resetProfile() {
    setProfile(undefined);
  }

  // Trigger login request
  async function triggerLoginRequest(username: string, password: string) {
    return await requestToken({
      body: { username, password },
      config: {
        headers: { 'content-Type': 'application/x-www-form-urlencoded' },
      },
    });
  }

  // Handle successful login
  function handleLoginResponse(response: CreateAdminTokenResponseBody | undefined) {
    if (!response) {
      throw new Error('ログインに失敗しました');
    }

    setToken({
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    });
  }

  function handleUserProfile(response: ReadAdminUserProfileResponse | undefined) {
    if (!response) {
      throw new Error('ユーザープロファイルの取得に失敗しました');
    }

    setSentryUser(response.adminUser.id, response.adminUser.username, response.adminUser.email);
    setProfile(response.adminUser);
  }

  /**
   * URLアクセス時にアクセストークンが存在すれば､
   * ユーザープロファイルを取得して判断する
   */
  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          startTransaction();
          const response = await getUserProfile();
          handleUserProfile(response);
          determineAuthState({ responseAdminUserProfile: response });
        } catch (e) {
          console.error(e);
          logout();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const AuthValue = {
    authState,
    setAuthState,
    accessToken,
    login,
    logout,
    isLoading: isTokenRequestLoading,
    profile,
  };

  return <AuthContext.Provider value={AuthValue}>{children}</AuthContext.Provider>;
};

/**
 *
 *
 *
 *
 * Helper Functions
 *
 *
 *
 *
 */

function setSentryUser(id: number, username: string, email: string) {
  Sentry.setUser({
    id: id,
    username: username,
    email: email,
  });
}
