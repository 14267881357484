const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';

function useAuthLS() {
  // const [value, setValue, remove] = useLocalStorage(ACCESS_TOKEN_KEY)
  const setToken = ({
    accessToken,
    refreshToken,
  }: {
    accessToken?: string;
    refreshToken?: string;
  }) => {
    try {
      if (accessToken) {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      }
      if (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getToken = (token: 'accessToken' | 'refreshToken') => {
    if (token === 'accessToken') return localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token === 'refreshToken') return localStorage.getItem(REFRESH_TOKEN_KEY);
    return null;
  };

  const removeToken = (token: 'accessToken' | 'refreshToken' | 'both') => {
    if (token === 'accessToken') localStorage.removeItem(ACCESS_TOKEN_KEY);
    if (token === 'refreshToken') localStorage.removeItem(REFRESH_TOKEN_KEY);
    if (token === 'both') {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  };

  return {
    setToken,
    getToken,
    removeToken,
  };
}

export { useAuthLS };
