import { sentryCreateBrowserRouter } from 'lib/sentry';
import { lazy, PropsWithChildren, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppShell } from '../layout/AppShell';
import { LoginPage } from '../pages/LoginPage';
import { AuthGuard } from './AuthGuard';
import { useAuth } from '@/hooks';
import { Role } from '@/types';

const LocationPage = lazy(() => import('../pages/LocationPage'));
const ProjectListPage = lazy(() => import('../pages/ProjectListPage'));
const ProjectPage = lazy(() => import('../pages/ProjectPage'));
const ProjectDetailPage = lazy(() => import('../pages/ProjectDetailPage'));
const ProjectLocationPage = lazy(() => import('../pages/ProjectLocationPage'));
const ProjectUtilityPage = lazy(() => import('../pages/ProjectUtilityPage'));
const ProjectGraphViewPage = lazy(() => import('../pages/ProjectGraphViewPage'));
const ProjectGraphEditPage = lazy(() => import('../pages/ProjectGraphEditPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const AdminUserListPage = lazy(() => import('../pages/AdminUserListPage'));
const AdminUserPage = lazy(() => import('../pages/AdminUserPage'));
const AdminUserDetailPage = lazy(() => import('../pages/AdminUserDetailPage'));
const AdminUserSettingPage = lazy(() => import('../pages/AdminUserSettingPage'));
const ClientUserListPage = lazy(() => import('../pages/ClientUserListPage'));
const ClientUserPage = lazy(() => import('../pages/ClientUserPage'));
const ClientUserDetailPage = lazy(() => import('../pages/ClientUserDetailPage'));
const ClientUserSettingPage = lazy(() => import('../pages/ClientUserSettingPage'));
const ClientUserPermissionPage = lazy(() => import('../pages/ClientUserPermissionPage'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const ProjectGroupListPage = lazy(() => import('../pages/ProjectGroupListPage'));
const ProjectGroupPage = lazy(() => import('../pages/ProjectGroupPage'));
const ProjectGroupDetailPage = lazy(() => import('../pages/ProjectGroupDetailPage'));
const ProjectGroupMembersPage = lazy(() => import('../pages/ProjectGroupMembersPage'));
const ProjectGroupClientUserPermissionsPage = lazy(
  () => import('../pages/ProjectGroupClientUserPermissionsPage'),
);
const ProjectGroupSettingPage = lazy(() => import('../pages/ProjectGroupSettingPage'));
const ProjectSummarySettingPage = lazy(() => import('../pages/ProjectSummarySettingPage'));
const ProjectSummarySettingUtilitiesEditPage = lazy(
  () => import('../pages/ProjectSummarySettingUtilitiesEditPage'),
);
const UnexpectedErrorPage = lazy(() => import('../pages/UnexpectedErrorPage'));

export const router = sentryCreateBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <UnexpectedErrorPage />,
  },
  {
    path: '/',
    element: (
      <RouteWrapper>
        <AppShell />
      </RouteWrapper>
    ),

    errorElement: (
      <RouteWrapper>
        <UnexpectedErrorPage />
      </RouteWrapper>
    ),
    children: [
      {
        path: '/',
        element: <DashboardPage />,
      },
      {
        path: '/location',
        element: <LocationPage />,
        children: [
          {
            path: 'add',
            element: <LocationPage />,
          },
        ],
      },
      {
        path: '/adminusers',
        element: (
          <RoleSplitter whiteList={['superadmin']}>
            <Outlet />
          </RoleSplitter>
        ),
        children: [
          {
            index: true,
            element: <AdminUserListPage />,
          },
          {
            path: 'add',
            element: <AdminUserListPage />,
          },
          {
            path: ':adminUserId',
            element: <AdminUserPage />,
            children: [
              {
                index: true,
                element: <AdminUserDetailPage />,
              },
              {
                path: 'detail',
                element: <AdminUserDetailPage />,
              },
              {
                path: 'setting',
                element: <AdminUserSettingPage />,
              },
            ],
          },
        ],
      },

      {
        path: '/clientusers',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ClientUserListPage />,
          },
          {
            path: 'add',
            element: <ClientUserListPage />,
          },
          {
            path: ':clientUserId',
            element: <ClientUserPage />,
            children: [
              {
                index: true,
                element: <ClientUserDetailPage />,
              },
              {
                path: 'detail',
                element: <ClientUserDetailPage />,
              },
              {
                path: 'permission',
                element: <ClientUserPermissionPage />,
              },
              {
                path: 'setting',
                element: <ClientUserSettingPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/projects',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ProjectListPage />,
          },
          {
            path: 'add',
            element: <ProjectListPage />,
          },
          {
            path: ':projectId',
            element: <ProjectPage />,
            children: [
              {
                index: true,
                element: <Navigate to={'detail'} replace />,
              },
              {
                path: 'detail',
                element: <ProjectDetailPage />,
                handle: {
                  crumb: (data: any) => data,
                },
              },
              {
                path: 'location',
                element: <ProjectLocationPage />,
              },
              {
                path: 'utility',
                element: <ProjectUtilityPage />,
              },
              {
                path: 'summary',
                element: <ProjectSummarySettingPage />,
              },
              {
                path: 'summary/edit',
                element: <ProjectSummarySettingUtilitiesEditPage />,
              },
              {
                path: 'graph',
                element: <ProjectGraphViewPage />,
              },
              {
                path: 'graph/edit',
                element: <ProjectGraphEditPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/projectgroups',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ProjectGroupListPage />,
          },
          {
            path: 'add',
            element: <ProjectGroupListPage />,
          },
          {
            path: ':projectGroupId',
            element: <ProjectGroupPage />,
            children: [
              {
                index: true,
                element: <ProjectGroupDetailPage />,
              },
              {
                path: 'detail',
                element: <ProjectGroupDetailPage />,
              },
              {
                path: 'project_members',
                element: <ProjectGroupMembersPage />,
              },
              {
                path: 'clientuser_permissions',
                element: <ProjectGroupClientUserPermissionsPage />,
              },
              {
                path: 'setting',
                element: <ProjectGroupSettingPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/notfound',
        element: <NotFoundPage />,
      },
      {
        path: '*',
        element: <Navigate to={'notfound'} replace />,
      },
    ],
  },
]);

function RouteWrapper({ children }: PropsWithChildren) {
  return (
    <AuthGuard redirectTo='/login'>
      <Suspense fallback={<div>Page is Loading...</div>}>{children}</Suspense>
    </AuthGuard>
  );
}

type RoleSplitterProps = {
  whiteList: Role[];
};

function RoleSplitter({ whiteList, children }: PropsWithChildren<RoleSplitterProps>) {
  const { profile } = useAuth();
  const userRole = profile?.role ?? 'UNKNOWN';

  if (userRole === 'UNKNOWN') {
    return <Navigate to={'/notfound'} replace />;
  }

  if (whiteList.includes(userRole)) {
    return <>{children}</>;
  }

  return <Navigate to={'/notfound'} replace />;
}
